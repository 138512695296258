@use '@angular/material' as mat;
// // Plus imports for other components in your app.

// // Include the common styles for Angular Material. We include this here so that you only
// // have to load a single css file for Angular Material in your app.
// // Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
//@include mat.all-legacy-component-typographies();
@include mat.core();

$mat-hiab-black: (
    50 : #e0e0e0,
    100 : #b3b3b3,
    200 : #808080,
    300 : #4d4d4d,
    400 : #262626,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #a6a6a6,
    A200 : #8c8c8c,
    A400 : #737373,
    A700 : #666666,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-hiab-red: (
    50 : #fae6e4,
    100 : #f2bfbc,
    200 : #ea958f,
    300 : #e26b62,
    400 : #db4b41,
    500 : #d52b1f,
    600 : #d0261b,
    700 : #ca2017,
    800 : #c41a12,
    900 : #ba100a,
    A100 : #ffe6e5,
    A200 : #ffb4b3,
    A400 : #ff8280,
    A700 : #ff6a66,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-hiab-silver: (
    50 : #f1f1f2,
    100 : #dcdddd,
    200 : #c5c6c7,
    300 : #adafb1,
    400 : #9c9ea0,
    500 : #8a8d8f,
    501 : #8a8d8f,
    600 : #828587,
    700 : #777a7c,
    800 : #6d7072,
    900 : #5a5d60,
    A100 : #d3e7fb,
    A200 : #a4cef7,
    A400 : #69b4ff,
    A700 : #50a7ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);




// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette($mat-hiab-black);
$app-accent:  mat.define-palette($md-hiab-silver);

// // The warn palette is optional (defaults to red).
$app-warn:    mat.define-palette($md-hiab-red);

// typografy (copy from legacy)
$app-typography: mat.define-typography-config(
    $font-family:   'Roboto, "Helvetica Neue", sans-serif',
    $headline-1:     mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
    $headline-2:     mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
    $headline-3:     mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
    $headline-4:     mat.define-typography-level(34px, 40px, 400),
    $headline-5:      mat.define-typography-level(24px, 32px, 400),
    $body-2:        mat.define-typography-level(14px, 24px, 500),
    $body-1:        mat.define-typography-level(14px, 20px, 400),
    $caption:       mat.define-typography-level(12px, 20px, 400),
    $button:        mat.define-typography-level(14px, 14px, 500),
);

// // Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-light-theme((
    color: (
        primary: $app-primary,
        accent: $app-accent, 
        warn: $app-warn
    ),
    typografy: $app-typography,
    density: -3,
));

.primary{
    background-color: mat.get-color-from-palette($app-primary, 400) !important;
    color: mat.get-color-from-palette($app-primary, '500-contrast') !important;
}


.accent{
    background-color: mat.get-color-from-palette($app-accent, 100) !important;
    color: mat.get-color-from-palette($app-accent, '500-contrast') !important;
}

.warn{
    background-color: mat.get-color-from-palette($app-warn, '500-contrast') !important;
    color: mat.get-color-from-palette($app-warn) !important;
}




// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
@include mat.all-component-themes($app-theme);
@include mat.all-component-typographies($app-typography);
