/* You can add global styles to this file, and also import other style files */

/* @import "~@angular/material/prebuilt-themes/indigo-pink.css"; */
json-editor,
json-editor > div,
json-editor jsoneditor-outer{
  display: flex;
  flex: 1 auto;
}


h2 {
  font-weight: 400;
  font-size: 18px;
}

body{
    height: 100%;
    margin: 0px;
    font-size: 14px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}



.fullscreen {
    min-height: 100%;
    box-sizing: border-box;
}

a {
    cursor: pointer;
}

.warnSnackbar{
    background-color: white;
    color: red;
    white-space: pre-wrap;
}

.warnSnackbar button{
  background-color: lightcoral;
  color: white !important;
}

a.mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 0px;
}

button.mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
